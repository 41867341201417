import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import CongratulationsLanding from '../components/congratulations/congratulations-landing';

const CongratulationsPage = () => (
  <Layout>
    <SEO title="Congratulations" />
    <CongratulationsLanding />
  </Layout>
);

export default CongratulationsPage;
