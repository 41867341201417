// Libs.
import React, { useState, useEffect, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { get } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// Deps.
import FormInput from '../common/form-input';
import { ValidateCodePasswordReset } from '../app/validate-code-password-reset';
import { ValidateCodeRegister } from '../app/validate-code-register';
import AppShown from '../app/common/app-shown';
import AppHidden from '../app/common/app-hidden';
import UserResetPassword from './user-reset-password';
import UserResetPasswordConfirmed from './user-reset-password-confirmed';
// State.
import crmService from '../../services/crmClientService';
import { useStateValue } from '../../store/state';
import { UserLoginComplete } from '../../store/user/action-creators';
import formValidators from '../../libs/form/form-validator';
import useCustomer from '../../hooks/useCustomer';
import AppLayout, { AppContext } from '../layout/AppLayout';
// Assets.
import styles from './user-login-form.module.scss';
import locale from '../../locale/locale.json';
import ErrorMessages from '../message/error-messages';
import pageAliases from '../../data/pageAliases';
import appAliases from '../../data/appAliases';
import { ResetCheckoutCustomer, ResetGuestCheckout } from '../../store/checkout/action-creators';
import ButtonLoader from '../common/loaders/button-loader';
import guestDestination from '../../libs/guestSignin';
// Hooks.
import NavigationDrawer, { useDrawer } from '../../hooks/useNavigationDrawer';
import navigate from '../../libs/navigate';
import getWindow from '../../libs/getWindow';
import { getViewingPlatform } from '../../libs/getViewingPlatform';
import { pushEvent } from '../../libs/dataLayer';

const UserLoginForm = ({ title, summary, onLogin, onLoginComplete, onError, isRegistered }) => {
  const { iOS, tallDevice } = getViewingPlatform();
  const app = useContext(AppContext);
  const prefix = app ? '/app' : '';
  const { destination, guestMode } = guestDestination(prefix);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [drawers, navigateDrawer] = useDrawer();
  const [submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [email, setEmail] = useState({ value: '', error: false });
  const [password, setPassword] = useState({ value: '', error: false });
  const [state, dispatch] = useStateValue();
  const { getCustomer } = useCustomer();
  const [submission, setSubmission] = useState(false);
  const [params, setParams] = useState(getWindow('location') || {});
  // React on access_token change, when defined trigger get customer.
  useEffect(() => {
    if (state.user.access_token) {
      getCustomer();
    }
  }, [state.user.access_token]);

  const [resetPasswordState, setResetPasswordState] = useState({ email: '', token: '' });
  const drawer = {
    resetPasswordState,
    setResetPasswordState,
  };

  const handleLogin = async (e) => {
    setSubmission(true);
    e.preventDefault();
    setSubmitted(true);

    const userLogin = {
      grant_type: 'password',
      username: email.value,
      password: password.value,
    };
    let token = '';

    if (!email.error && !password.error) {
      onLogin && onLogin();
      token = await executeRecaptcha('login');
      crmService.setRecaptchaToken(token);
      crmService
        .userLogin(userLogin)
        .then((session) => {
          crmService.resetRecaptchaToken();
          pushEvent('Interaction', 'Login', `Successful login using ${userLogin.username}`);
          dispatch(UserLoginComplete(session));
          dispatch(ResetCheckoutCustomer());
          dispatch(ResetGuestCheckout());
          getCustomer();
          setSubmission(false);
          // Display customer view when session start.
          setTimeout(() => {
            if (app && onLoginComplete) {
              onLoginComplete(true);
            } else {
              navigate(destination);
            }
          }, 300);
        })
        .catch(async (error) => {
          onError && onError();
          onLogin && onLogin(false);
          token = await executeRecaptcha('login');
          pushEvent('Interaction', 'Login', `Login failed ${userLogin.username}`);
          // console.log(error);
          if (error.requestStatus === 400) {
            if (error.error && error.error === 'twostepvalidation_required') {
              if (app) {
                navigateDrawer('verifyAccountContinue');
              } else {
                navigate(pageAliases.incomplete, {
                  state: { userEmail: email.value, context: 'registration' },
                });
              }
            } else {
              setFormErrors([locale.loginPage.credentialsError]);
            }
          } else {
            setFormErrors([locale.loginPage.requestError]);
          }
          crmService.setRecaptchaToken(token);
          setSubmission(false);
        });
    }
  };
  useEffect(() => {
    setParams(getWindow('location') || {});
  }, []);
  const formClass = app ? styles.userLoginFormMobile : styles.userLoginFormWeb;
  const linkClass = app ? styles.userLoginForgotPasswordMobile : styles.userLoginForgotPasswordWeb;
  const isAccount = get(params, 'pathname', '') === appAliases.appAccountProfile;
  return (
    <>
      <form noValidate className={formClass} onSubmit={(e) => handleLogin(e)} data-app={app}>
        <fieldset>
          {title && (
            <>
              <AppHidden>
                <h2>{title}</h2>
              </AppHidden>
              <AppShown>
                <h1>{title}</h1>
              </AppShown>
            </>
          )}
          {app && isRegistered && (
            <>
              <h3>{locale.account.welcomeCta}</h3>
              <div className={styles.userLoginWelcomeDescription}>{locale.account.welcomeDescription}</div>
            </>
          )}
          {summary && <div dangerouslySetInnerHTML={{__html: summary}}/>}
          <ErrorMessages messages={formErrors} />
          <AppShown>
            <label htmlFor="emailInput">{locale.form.appEmail}</label>
          </AppShown>
          <FormInput
            type="email"
            id="emailInput"
            placeholder={locale.form.emailPlaceholder}
            required={true}
            maxLength={100}
            onChange={(e) => formValidators.email(e.target.value, email, setEmail)}
            value={email.value}
            error={submitted && email.error}
            errorMsg={locale.form.emailError}
          />
          <AppShown>
            <label htmlFor="passwordInput">{locale.form.password}</label>
          </AppShown>
          <FormInput
            type="password"
            id="passwordInput"
            value={password.value}
            required={true}
            maxLength={100}
            placeholder={locale.form.password}
            onChange={(e) => formValidators.exists(e.target.value, password, setPassword)}
            error={submitted && password.error}
            errorMsg={locale.form.passwordEmpty}
          />
          <AppHidden>
            <Link className={linkClass} to={prefix + pageAliases.passwordReset}>
              {locale.account.forgotPassword}
            </Link>
          </AppHidden>
          <AppShown>
            <div
              role="button"
              tabIndex="0"
              className={isRegistered ? styles.userLoginForgotPasswordMobileIsRegistered : linkClass}
              onClick={() => navigateDrawer('resetPassword')}
            >
              {locale.account.forgotPassword}
            </div>
          </AppShown>
        </fieldset>
        <button>
          {locale.account.signIn}
          {submission && <ButtonLoader />}
        </button>
      </form>
      <NavigationDrawer
        header={isAccount ? 'Account' : 'Rewards'}
        drawerStatus={drawers.includes('resetPassword')}
      >
        <UserResetPassword drawer={drawer} />
      </NavigationDrawer>
      <NavigationDrawer
        header={''}
        drawerStatus={drawers.includes('verifyAccountReset')}
        isRegistered={isRegistered}
        menuHide={true}
      >
        {iOS && <div style={{ height: tallDevice ? 40 : 20, backgroundColor: '#f8f4ed' }}></div>}
        <ValidateCodePasswordReset userEmail={resetPasswordState.email} drawer={drawer} />
      </NavigationDrawer>
      <NavigationDrawer
        header={isAccount ? 'Account' : 'Rewards'}
        drawerStatus={drawers.includes('passwordConfirmed')}
      >
        <UserResetPasswordConfirmed
          pageData={locale.changePasswordPage}
          token={resetPasswordState.resetToken}
        />
      </NavigationDrawer>
      <NavigationDrawer
        header={''}
        drawerStatus={drawers.includes('verifyAccountContinue')}
        isRegistered={isRegistered}
        menuHide={true}
      >
        <ValidateCodeRegister userEmail={email.value} drawer={drawer} />
      </NavigationDrawer>
    </>
  );
};

UserLoginForm.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
};

export default UserLoginForm;