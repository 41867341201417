// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Components.
import UserLoginForm from '../user/user-login-form';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets.
import styles from './card-success-CTA.module.scss';
import pageAliases from '../../data/pageAliases';

// @todo Pass the user first name from registration form.
const CardSuccessCTA = ({ cta, userFirstName = '' }) => {
  const imgPlaceholderXL = useImageStaticQuery('earn-rewards/header-background-xl.png');
  const imgPlaceholderL = useImageStaticQuery('earn-rewards/header-background-lg.png');

  return (
    <div className={styles.cardSuccessCta}>
      <div className={styles.cardSuccessCtaImageXl}>
        <Img fluid={imgPlaceholderXL.childImageSharp.fluid}/>
      </div>
      <div className={styles.cardSuccessCtaImageL}>
        <Img fluid={imgPlaceholderL.childImageSharp.fluid}/>
      </div>
      <div className={styles.cardSuccessCtaBlock}>
        <h1 className={styles.cardSuccessCtaHeader}>{cta.header}</h1>
        <h3 className={styles.cardSuccessCtaTitle}>
          {cta.title}
          {userFirstName !== '' && (
            <span className={styles.cardSuccessCtaName}>,&nbsp;{userFirstName}</span>
          )}
          !
        </h3>
        <p className={styles.cardSuccessCtaDescription}>{cta.description}</p>
        <UserLoginForm destination={pageAliases.accountProfile}/>
      </div>
    </div>
  );
};

CardSuccessCTA.propTypes = {
  cta: PropTypes.object.isRequired,
  userFirstName: PropTypes.string,
};

export default CardSuccessCTA;
