import React, { useContext } from 'react';
import styles from './user-reset-password.module.scss';
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import UserConfirmResetPasswordForm from './user-confirm-reset-password-form';
import { Link } from 'gatsby';
import pageAliases from '../../data/pageAliases';
import locale from '../../locale/locale';
import { AppContext } from '../layout/AppLayout';
import AppHidden from '../app/common/app-hidden';

const UserResetPasswordConfirmed = ({ pageData, cancel = false, ...props }) => {
  const imgThanks = useImageStaticQuery('Clementines.png');
  const app = useContext(AppContext);

  const backgroundFluidImageStack = {
    md: imgThanks.lg.fluid,
    lg: imgThanks.lg.fluid,
    xl: imgThanks.childImageSharp.fluid,
  };

  return (
    <div className={styles.userResetPasswordMain} data-app={app}>
      <AppHidden>
        <Link to={pageAliases.accountProfile} className={styles.userResetTurnBack}>
          {locale.accountProfilePage.titles.pageTitleOuter}
        </Link>
      </AppHidden>
      <HeadingFullsize
        imageStack={backgroundFluidImageStack}
        title={app ? pageData.appTitle : pageData.title}
        subTitle={app ? pageData.title : pageData.subTitle}
        description={!app && pageData.description}
      >
        {cancel && <div className={styles.userResetPasswordMainCancel} onClick={() => cancel(false)}>Cancel</div>}
        <UserConfirmResetPasswordForm {...props} />
      </HeadingFullsize>
    </div>
  );
};

export default UserResetPasswordConfirmed;
