// Libs
import React, { useState, useEffect, useContext } from 'react';
import { assign, has } from 'lodash';
// State
import formValidators from '../../libs/form/form-validator';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Deps
import { Link } from 'gatsby';
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import FormInput from '../common/form-input';
import crmService from '../../services/crmClientService';
// Assets
import locale from '../../locale/locale';
import pageAliases from '../../data/pageAliases';
import appAliases from '../../data/appAliases';
import styles from './user-reset-password.module.scss';
import formStyles from './user-reset-password-form.module.scss';
import PagePreloader from '../common/loaders/page-preloader';
import ButtonLoader from '../common/loaders/button-loader';
import FormLoader from '../common/loaders/form-loader';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Layout from '../layout/layout';
import { AppContext } from '../layout/AppLayout';
import { useDrawer } from '../../hooks/useNavigationDrawer';
import navigate from '../../libs/navigate';
import { red } from '@material-ui/core/colors';
import getWindow from '../../libs/getWindow';

const UserResetPassword = ({ drawer, token = '' }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState({
    value: '',
    error: false,
    errorMessage: locale.form.emailError,
  });
  const setAndCheckEmail = (data) => {
    formValidators.email(email.value, email, setEmail);
    setEmail(data);
  };
  const [submission, setSubmission] = useState(false);
  const imgPlaceholder = useImageStaticQuery('Avocadoes.png');
  const backgroundFluidImageStack = {
    lg: imgPlaceholder.lg.fluid,
    xl: imgPlaceholder.childImageSharp.fluid,
  };
  const pageLabels = {
    ...locale.resetPasswordPage.initial,
  };
  const isApp = useContext(AppContext);
  const [, navigateDrawer] = useDrawer();

  const confirmToken = async (emailToken) => {
    // Don't resubmit.
    if (submission) {
      return;
    }

    setSubmission(true);
    try {
      let token = await executeRecaptcha('passwordReset');
      crmService.setRecaptchaToken(token);

      const firstResult = await crmService.passwordTokenConfirm(emailToken);
      if (firstResult.ResponseCode && firstResult.ResponseCode === '000') {
        navigate(pageAliases.passwordResetConfirm, { state: { token: emailToken.Token } });
      } else {
        navigate(pageAliases.incomplete, { state: { context: 'resetPassword' } });
      }
      setSubmission(false);
    } catch (e) {
      // 103 token expired
      // 104 token used
      const responseCode = e.ResponseCode;
      // Are we in the app context with a drawer?
      const isChangeDrawer = has(drawer, 'changeDrawer');
      // Build data for redirection handling, defaulting to the fallback error handling, incomplete.
      const redirection = {
        destination: isChangeDrawer ? 'incomplete' : pageAliases.incomplete,
        state: { context: 'resetPassword', expiredToken: emailToken },
      };

      // Determine if this had a reasonable error code we can react too.
      if (responseCode === '104') {
        if (isChangeDrawer) {
          redirection.destination = 'expire';
        } else {
          redirection.destination = pageAliases.expire;
          redirection.state.context = 'registration';
        }
      }

      // console.log('Reset Redirection', isChangeDrawer, redirection);

      // Finally direct to the error based on context
      isChangeDrawer ?
        drawer.changeDrawer(redirection) :
        navigate(redirection.destination, { state: redirection.state });

      setSubmission(false);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (submission) {
      return;
    }

    const { search, pathname } = getWindow('location');
    const cleanSearchString = search.indexOf("?") >= 0 ? search.split("?")[1] : search;
    const urlSegments = cleanSearchString.split("&").filter(segment => segment.indexOf("drawer") < 0)
    setSubmission(true);
    setErrorMessage('');
    formValidators.email(email.value, email, setEmail);
    if (!email.error && email.value) {
      let token = await executeRecaptcha('passwordReset');
      crmService.setRecaptchaToken(token);

      crmService
        .passwordReset(email.value)
        .then((confirmToken) => {

          crmService.resetRecaptchaToken();

          setSubmission(false);

          if (has(drawer, 'setResetPasswordState')) {
            drawer.setResetPasswordState({ email: email.value });
            // navigateDrawer('verifyAccountReset');
            navigateDrawer(false);
            navigate(`${pathname}?${urlSegments.join("&")}&drawer=verifyAccountReset&emailAddress=${email.value}`)
          } else {
            navigate(pageAliases.thankyou, {
              state: { context: 'resetPassword', userEmail: email.value },
            });
          }
        })
        .catch(async (error) => {
          token = await executeRecaptcha('passwordReset');
          crmService.setRecaptchaToken(token);
          if (error.ResponseCode === '202') {
            setEmail({ value: email.value, error: true, errorMessage: error.ResponseBody });
          } else if (error.ResponseCode !== '000') {
            setErrorMessage(error.ResponseBody);
          }
          setSubmission(false);
        });
    } else {
      setSubmission(false);
    }
  };

  useEffect(() => {
    if (isApp) {
      assign(pageLabels, {
        title: '',
        description: '',
        subTitle: '',
      });
      backgroundFluidImageStack['lg'] = '';
      backgroundFluidImageStack['xl'] = '';
    }

    if (token !== '') {
      const emailToken = {
        Token: token,
      };
      confirmToken(emailToken);
    }
  }, []);

  return (
    <div className={styles.userResetPasswordMain}>
      <Link to={pageAliases.accountProfile} className={styles.customerTurnBack}></Link>
      {token !== '' ? (
        <>
          <PagePreloader/>
        </>
      ) : (
        <>
          <HeadingFullsize
            imageStack={backgroundFluidImageStack}
            title={pageLabels.title}
            subTitle={pageLabels.subTitle}
            description={isApp ? pageLabels.appDescription : pageLabels.description}
          >
            <div className={formStyles.userResetPasswordForm}>
              <form noValidate onSubmit={handleReset}>
                <FormInput
                  type="email"
                  value={email.value}
                  name="email"
                  id="email"
                  placeholder="Email address"
                  error={email.error}
                  onChange={(e) => setAndCheckEmail({ ...email, value: e.target.value })}
                  maxLength={100}
                  required={true}
                  errorMsg={email.error && email.errorMessage}
                />
                <button type="submit">
                  Submit
                  {submission && <ButtonLoader/>}
                </button>
                {submission && (
                  <div className={formStyles.userResetPasswordError}>{errorMessage}</div>
                )}
              </form>
            </div>
          </HeadingFullsize>
        </>
      )}
    </div>
  );
};

export default UserResetPassword;
