/* eslint-disable import/no-duplicates */
// Libs
import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import { has } from 'lodash';
// State
import crmService from '../../services/crmClientService';
import formValidators from '../../libs/form/form-validator';
import { AppContext } from '../layout/AppLayout';
// Deps
import FormInput from '../common/form-input';
import ButtonLoader from '../common/loaders/button-loader';
// Assets
import appPageAliases from '../../data/appAliases.json';
import pageAliases from '../../data/pageAliases.json';
import locale from '../../locale/locale.json';
import styles from './user-reset-password-form.module.scss';
import formStyles from './user-reset-password-form.module.scss';
// Hooks.
import { useDrawer } from '../../hooks/useNavigationDrawer';
import { useStateValue } from '../../store/state';
// Custom Libs.
import {addNotification, clearNotifications} from '../../libs/notify';
import navigate from '../../libs/navigate';
import getWindow from '../../libs/getWindow';

const UserConfirmResetPasswordForm = ({ token, type }) => {
  const params = getWindow('location');
  const isPasswordReset = type === 'reset';
  const [state] = useStateValue();
  const app = useContext(AppContext);
  const [, navigateDrawer] = useDrawer();
  const [errorMessage, setStateErrorMessage] = useState('');
  const [submission, setSubmission] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({
    value: '',
    error: false,
    errorMessage: locale.form.passwordError,
  });
  const [password, setPassword] = useState({
    value: '',
    error: false,
    errorMessage: locale.form.passwordError,
  });
  const [passwordRepeat, setPasswordRepeat] = useState({
    value: '',
    error: false,
    errorMessage: locale.form.confirmPasswordError,
  });

  const setErrorMessage = (data, force = false) => {
    if (app && (data !== errorMessage)) {
      setStateErrorMessage(data);
      if (app && errorMessage !== '') {
          addNotification(errorMessage, 'error');
        }
      clearNotifications();
    }
    force && addNotification(data, 'error');
  };

  const handleReset = (e) => {
    setSubmission(true);
    let crmAction;
    e.preventDefault();
    setErrorMessage('');

    // console.log('reset submit', password, passwordRepeat);
    if (passwordRepeat.value !== password.value) {
      setPasswordRepeat({ ...passwordRepeat, error: true });
    } else if (!password.error && password.value && passwordRepeat.value === password.value) {
      if (isPasswordReset) {
        crmAction = crmService.passwordResetComplete({
          Token: token,
          NewPassword: password.value,
          ConfirmNewPassword: passwordRepeat.value,
        });
      } else if (!currentPassword.error && currentPassword.value) {
        crmAction = crmService.passwordUpdate(
          state.user.CRMCustomerID,
          {
            Password: currentPassword.value,
            NewPassword: password.value,
            ConfirmNewPassword: passwordRepeat.value,
          },
          state.user.access_token
        );
      } else {
        setCurrentPassword({ ...currentPassword, error: true });
      }

      if (crmAction) {
        crmAction
          .then((session) => {
            setSubmission(false);
            if (!session) {
              setErrorMessage(locale.form.invalidFormFields);
            } else {
              if (app) {
                navigate(`${params.pathname}?drawer=userLogin`);
              } else {
                navigate(isPasswordReset ? pageAliases.login : pageAliases.accountProfile);
              }

              addNotification(locale.passwordResetConfirmation.resetSubmissionSuccess);
            }
          })
          .catch((error) => {
            if (error && has(error, 'ResponseCode') && error.ResponseCode === '202') {
              setErrorMessage(locale.form.passwordInvalid, true);
              setSubmission(false);
              return;
            }

            try {
              const errors = JSON.parse(error.ResponseBody)['m.NewPassword']['Errors'];
              let errorMessage = '';
              for (error of errors) {
                errorMessage += ` ${error.ErrorMessage}`;
              }
              setErrorMessage(errorMessage);
            } catch (e) {
              setSubmission(false);
              setErrorMessage(errorMessage);
            }
          });
      }
      else {
        setSubmission(false);
      }
    } else {
      setSubmission(false);
      setErrorMessage(locale.form.invalidFormFields);
    }
  };

  return (
    <div className={styles.userResetPasswordForm}>
      <form onSubmit={handleReset}>
        {!isPasswordReset && (
          <>
            <FormInput
              type="password"
              value={currentPassword.value}
              name="current-password"
              placeholder="Current Password"
              error={currentPassword.error}
              onChange={(e) =>
                formValidators.password(e.target.value, currentPassword, setCurrentPassword)
              }
              maxLength={100}
              required={true}
              errorMsg={currentPassword.errorMessage}
            />
            {!app && errorMessage !== '' && (
              <div className={formStyles.userResetPasswordError}>{errorMessage}</div>
            )}
          </>
        )}
        <FormInput
          type="password"
          value={password.value}
          name="new-password"
          placeholder="New Password"
          error={password.error}
          onChange={(e) => formValidators.password(e.target.value, password, setPassword)}
          maxLength={100}
          required={true}
          errorMsg={password.errorMessage}
        />
        <FormInput
          type="password"
          value={passwordRepeat.value}
          name="confirm-password"
          placeholder="Confirm Password"
          error={passwordRepeat.error}
          onChange={(e) =>
            formValidators.password(e.target.value, passwordRepeat, setPasswordRepeat)
          }
          maxLength={100}
          required={true}
          errorMsg={passwordRepeat.errorMessage}
        />
        {app && (
          <div className={styles.userResetPasswordAppMessage}>
            {locale.form.passwordFootnote}
          </div>
        )}
        <button className={app && styles.userResetPasswordAppButton}>
          {submission && <ButtonLoader />}
          Save
        </button>
      </form>
    </div>
  );
};

UserConfirmResetPasswordForm.defaultProps = {
  token: '',
  type: 'reset',
};

UserConfirmResetPasswordForm.propTypes = {
  token: PropTypes.string,
  type: PropTypes.string,
};

export default UserConfirmResetPasswordForm;
