// Libs.
import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Components.
import CardSuccessCTA from './card-success-CTA';
import LongosAppCta from '../earn-rewards/longos-app-cta';
// Data.
import data from '../../mocks/congratulations-landing';
// Assets.
import styles from './congratulations-landing.module.scss';

const CongratulationsLanding = () => {
  const imgGrabCardInStore = useImageStaticQuery('congratulations/Earn_and_Spend_Points_Final_V3.jpg');

  return (
    <div className={styles.congratulationsLandingContainerColored}>
      <CardSuccessCTA cta={data.cardSuccessCta} />

      <div className={styles.congratulationsLandingMorePart}>
        <div className={styles.congratulationsLandingMorePartBlock}>
          <h3 className={styles.congratulationsLandingMorePartTitle}>
            {data.moreWaysToEarn.title}
          </h3>
        </div>
        <div className={styles.congratulationsLandingContent}>
          <LongosAppCta cta={data.downloadAppCta} />

          <div className={styles.congratulationsLandingGrabInStore}>
            <div className={styles.congratulationsLandingGrabInStoreMedia}>
              <Img
                fluid={imgGrabCardInStore.childImageSharp.fluid}
                className={styles.congratulationsLandingGrabInStoreImage}
                alt={data.grabCardInStore.alternativeText}
              />
            </div>
            <div className={styles.congratulationsLandingGrabInStoreContent}>
              <h3 className={styles.congratulationsLandingGrabInStoreTitle}>
                {data.grabCardInStore.title}
              </h3>
              <p className={styles.congratulationsLandingGrabInStoreDescription}>
                {data.grabCardInStore.summary}
              </p>
              <Link to={data.grabCardInStore.link}>{data.grabCardInStore.linkTitle}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CongratulationsLanding.propTypes = {
  data: PropTypes.shape({
    moreWaysToEarn: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    grabCardInStore: PropTypes.shape({
      title: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      alternativeText: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      linkTitle: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default CongratulationsLanding;
