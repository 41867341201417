import React, { useContext } from 'react';

import styles from './page-preloader.module.scss';
import FormLoader from './form-loader';
import { AppContext } from '../../layout/AppLayout';

const PagePreloader = () => {
  const app = useContext(AppContext);

  return (
    <div className={styles.preloaderContainer} data-app={app}>
      <FormLoader />
    </div>
  );
};

export default PagePreloader;
