import pageAliases from '../data/pageAliases';
import getWindow from './getWindow';

const guestDestination = (prefix = '') => {
  const search = getWindow('location.search') || '';
  const qs = new URLSearchParams(search);
  const queryDestination = decodeURIComponent(qs.get('destination'));
  const destination = queryDestination !== 'null' ? queryDestination : `${prefix}${pageAliases.accountProfile}`;
  const guestMode = decodeURIComponent(qs.get('guest')) === 'true';
  return { guestMode, destination };
};

export default guestDestination;
