import React, { useState } from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// Services.
import crmService from '../../services/crmClientService';
// Custom Libs.
import { addNotification } from '../../libs/notify';
import navigate from '../../libs/navigate';
import getWindow from '../../libs/getWindow';
// Assets.
import locale from '../../locale/locale.json';
import { ValidateCode } from './validate-code';

export const ValidateCodeRegister = ({ userEmail, drawer }) => {
  const params = getWindow('location');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contextData = {
    userEmail,
  };

  const handleSubmitCode = (userEmail, code) => {
    return crmService
      .customerValidateByCode(userEmail, code)
      .then(() => {
        if (has(drawer, 'setRegisterDrawer')) {
          drawer.setRegisterDrawer(false);
        }

        if (has(drawer, 'setLoginDrawer')) {
          drawer.setLoginDrawer(true);
        }

        addNotification(locale.registrationPage.messages.complete);
        navigate(`${params.pathname}?drawer=userLoginWelcome`);
      });
  };

  const handleResendCode = async (userEmail) => {
    const token = await executeRecaptcha('resendRegistrationCode');
    crmService.setRecaptchaToken(token);
    return crmService.registrationEmailTokenResend(userEmail).then(() => {
      addNotification(locale.registrationPage.messages.codeResend);
      crmService.resetRecaptchaToken();
    }).catch(() => {
      addNotification(locale.registrationPage.messages.codeResendError, 'error');
    });
  };

  return (
    <ValidateCode
      subTitle={locale.registrationPage.validate.subTitle}
      description={locale.registrationPage.validate.description}
      resendCodeCallback={handleResendCode}
      submitCodeCallback={handleSubmitCode}
      contextData={contextData}
      isRegistered={drawer.isRegisterDrawer}
    />
  );
};

ValidateCodeRegister.propTypes = {
  userEmail: PropTypes.string,
  drawer: PropTypes.object,
};
