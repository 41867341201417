import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components.
import ReactCodeInput from 'react-code-input';
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import ButtonLoader from '../common/loaders/button-loader';
// Services.
import crmService from '../../services/crmClientService';
// Hooks.
import { useDrawer } from '../../hooks/useNavigationDrawer';
// Assets.
import locale from '../../locale/locale.json';
import styles from './validate-code.module.scss';
import { addNotification } from '../../libs/notify';
import navigate from '../../libs/navigate';
import getWindow from '../../libs/getWindow';

export const ValidateCode = ({
  subTitle,
  description,
  resendCodeCallback,
  submitCodeCallback,
  contextData,
  isRegistered,
}) => {
  const [code, setCode] = useState('');
  const [codeSubmission, setCodeSubmission] = useState(false);
  const [resendSubmission, setResendSubmission] = useState(false);
  let userEmail = contextData.userEmail;
  if (userEmail === '') {
    const { search, pathname } = getWindow('location');
    const cleanSearchString = search.indexOf("?") >= 0 ? search.split("?")[1] : search;
    const urlSegments = cleanSearchString.split("&").filter(segment => segment.indexOf("emailAddress") >= 0)
    if (urlSegments.length > 0 ) {
      userEmail = urlSegments[0].split("=")[1];
    }
  }

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleSubmitCode = async () => {
    let isValid = true;
    if (code.length < 6) {
      addNotification(locale.resetPasswordPage.validate.errors.codeLength, 'error');
      isValid = false;
    }

    if (userEmail === '') {
      addNotification(locale.resetPasswordPage.validate.errors.emptyEmail, 'error');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      setCodeSubmission(true);
      await submitCodeCallback(userEmail, code);
      setCodeSubmission(false);
    } catch (e) {
      addNotification(e.message, 'error');
      setCodeSubmission(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setResendSubmission(true);
      await resendCodeCallback(userEmail);
      setResendSubmission(false);
    } catch (e) {
      addNotification(e.ResponseBody);
      setResendSubmission(false);
    }
  };

  return (
    <div className={styles.validateCodeContainer} data-host={isRegistered ? 'register' : 'forgotPassword'}>
      <HeadingFullsize
        title={isRegistered ? subTitle : locale.resetPasswordPage.validate.title}
        subTitle={isRegistered ? locale.registrationConfirmation.pageTitle : subTitle}
        secondTitle={isRegistered ? subTitle : false}
        description={description}
        imageStack={{}}
      >
        <ReactCodeInput
          className={styles.validateCodeCodeForm}
          type="number"
          fields={6}
          onChange={handleCodeChange}
        />
        <div className={styles.validateCodeActionsContainer}>
          <button onClick={handleResendCode} className={styles.validateCodeResendButton}>
            {locale.resetPasswordPage.validate.buttons.resend}
            {resendSubmission && <ButtonLoader />}
          </button>
          <button onClick={handleSubmitCode} className={styles.validateCodeSubmitButton}>
            {locale.resetPasswordPage.validate.buttons.submit}
            {codeSubmission && <ButtonLoader />}
          </button>
        </div>
      </HeadingFullsize>
    </div>
  );
};

ValidateCode.propTypes = {
  subTitle: PropTypes.string,
  description: PropTypes.string,
  resendCodeCallback: PropTypes.func,
  submitCodeCallback: PropTypes.func,
  contextData: PropTypes.object,
};
