// Libs.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// Services.
import crmService from '../../services/crmClientService';
// Hooks.
import { useDrawer } from '../../hooks/useNavigationDrawer';
// Assets.
import locale from '../../locale/locale.json';
import { ValidateCode } from './validate-code';
import { addNotification } from '../../libs/notify';

export const ValidateCodePasswordReset = ({ userEmail, drawer }) => {
  const [, navigateDrawer] = useDrawer();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contextData = {
    userEmail,
  };

  const handleSubmitCode = (userEmail, code) => {
    return crmService.customerPasswordResetByCode(userEmail, code).then((resetToken) => {
      if (resetToken) {
        const resetPasswordState = {
          ...drawer.resetPasswordState,
          resetToken,
        };

        drawer.setResetPasswordState(resetPasswordState);
        addNotification(locale.resetPasswordPage.validate.messages.verified);
        navigateDrawer('passwordConfirmed', true);
      }
    });
  };

  const handleResendCode = async (userEmail) => {
    const token = await executeRecaptcha('resendResetPasswordCode');
    crmService.setRecaptchaToken(token);
    return crmService.passwordReset(userEmail).then(() => {
      addNotification(locale.resetPasswordPage.validate.messages.codeResend);
      crmService.resetRecaptchaToken();
    }).catch(() => {
      addNotification(locale.resetPasswordPage.validate.messages.codeResendError, 'error');
    });
  };

  return (
    <ValidateCode
      subTitle={locale.resetPasswordPage.validate.subTitle}
      description={locale.resetPasswordPage.validate.description}
      resendCodeCallback={handleResendCode}
      submitCodeCallback={handleSubmitCode}
      contextData={contextData}
    />
  );
};

ValidateCodePasswordReset.propTypes = {
  userEmail: PropTypes.string,
  drawer: PropTypes.object,
};
